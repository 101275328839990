/* eslint camelcase: off */

import { trackPromise } from 'react-promise-tracker'
import { parseResponse, responseTypes } from '../../lib'
import { loaderArea } from '../../utils'

import log from 'loglevel'

const apiPath = process.env.REACT_APP_API_URL

export const startExport = (industryID, sectorID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const exportInfo = { industryID, sectorID }

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(exportInfo)
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment/export`, options), loaderArea.ASSESSMENT_EXPORT)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to start export.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

export const downloadExport = (assessmentExportID, fireSuccess = () => { }, fireFailure = () => { }) => {
  return async (dispatch, getState) => {
    try {
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/assessment/export/download/${assessmentExportID}`, options), loaderArea.ASSESSMENT_DOWNLOAD)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data, name } = await response.json()

        if (!data && !name) { fireFailure(parseResponse) }

        fireSuccess({ data, name })

        return true
      } else {
        fireFailure(parsedResponse)

        log.info('Failed to download export.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}
