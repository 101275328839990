import React, { useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { changeUserAssessmentAnswer, updateAssessmentStatus, resetAssessment, getUserAssessmentResults } from '../../redux/actions'
import { Form } from 'reactstrap'
import AssessmentQuestionContent from './AssessmentQuestionContent'
import { NotificationToast } from '../../components/tools'
import { assessmentStatus } from '../../utils'

const AssessmentQuestion = (props) => {
  const {
    currentQuestion = {}, setCurrentQuestionIndex, currentQuestionIndex, userAssessmentID,
    localQuestions, setLocalQuestions
  } = props

  const { userID } = useSelector(state => state.auth)
  const { userSelectedOption = null, questionOptions = [] } = currentQuestion
  const dispatch = useDispatch()

  // question option object that has been selected by the user
  const [answer, setAnswer] = useState({})

  // controls the state of the selected answer option as the current question changes
  useMemo(() => {
    // If there is an id value in the current question obj for a selected option
    if (userSelectedOption && questionOptions?.length) {
      // if the currently set answer is not the option for the current question
      if (answer?.questionOptionID !== userSelectedOption) {
        // find the answer obj that matches the user selected option
        const userAnswer = questionOptions.find(x => x.questionOptionID === userSelectedOption)
        if (userAnswer) { setAnswer(userAnswer) } else { setAnswer({}) }
      }
      // otherwise if we have a answer selected but there is no userSelectedOption in the current question, reset the answer obj
    } else if (Object.keys(answer).length && !userSelectedOption) {
      setAnswer({})
    }
  }, [userSelectedOption, questionOptions, answer])

  // button conditions for appearence and/or disabled state
  const nextButtonCondition = Boolean(answer && Object.keys(answer).length && (!currentQuestionIndex || localQuestions[currentQuestionIndex + 1]))
  const previousButtonCondition = Boolean(currentQuestionIndex)
  const lastQuestionCondition = Boolean((currentQuestionIndex + 1 === localQuestions.length) && (currentQuestion.userSelectedOption !== null))

  // fires when a question's answer has been successfully dispatched
  const fireAnswerSuccess = (qoID) => {
    // use the questionOptionID of the user's selection to create a new question obj
    const updatedQ = { ...currentQuestion, userSelectedOption: qoID }
    // clone the current set of local questions
    const newQuestions = [...localQuestions]
    // replace the currentQuestion with the new one
    newQuestions.splice(currentQuestionIndex, 1, updatedQ)
    setLocalQuestions(newQuestions)

    // If we are not on the last index, move to the next question
    const nextIndex = currentQuestionIndex + 1
    if (nextIndex !== localQuestions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    }
  }

  // fires when a question's answer dispatch has failed
  const fireAnswerFailure = () => {
    NotificationToast(true, 'Unable to Process Answer Selection.')
  }

  // Handles dispatch of updating a user's response to a question
  const handleAnswerQuestion = (questionID, questionOptionID, fullAnswerObj) => {
    const info = { questionID, questionOptionID, userAssessmentID, userID }
    dispatch(changeUserAssessmentAnswer(info, fireAnswerSuccess, fireAnswerFailure))
  }

  const fireResultsSuccess = (id) => {
    // redirect the user to the results page with feedback survey, and reset the assessment
    props.history.push(`/assessment-results/${id}?survey=1`)
    dispatch(resetAssessment())
  }
  // fires when assessment status successfully updated
  const fireCompleteSuccess = (id) => {
    NotificationToast(false, 'Successfully completed assessment!')
    // fetch results
    dispatch(getUserAssessmentResults({ userID, userAssessmentID: id }, fireResultsSuccess, fireCompleteFailure))
  }

  // fires when assessment status update failed
  const fireCompleteFailure = () => {
    NotificationToast(true, 'Something went wrong. Please try again later.')
  }

  // update the status of the assessment to complete
  const handleCompleteAssessment = () => {
    // check to be sure all questions have been answered
    const incompleteAnswersCheck = localQuestions.find(x => !x.userSelectedOption)

    // if any unanswered questions are found, alert the user
    if (incompleteAnswersCheck) {
      return NotificationToast(true, 'Cannot complete assessment with unanswered questions')
    }

    // otherwise fire the update dispatch
    const info = { userAssessmentID, userID, status: assessmentStatus.COMPLETED }
    dispatch(updateAssessmentStatus(info, fireCompleteSuccess, fireCompleteFailure))
  }

  return (
    <Form
      className='d-flex flex-column col-12 justify-content-center align-items-center'
    >
      <div className='row d-flex align-items-start justify-content-between'>
        <div className='col-2 order-lg-1 d-flex align-items-center justify-content-start justify-content-lg-end mt-4'>
          {previousButtonCondition &&
            <button
              type='button'
              onClick={() => { setCurrentQuestionIndex(currentQuestionIndex - 1) }}
              className='cursor-pointer py-1 px-2 d-flex align-items-center bg-white border-0 rounded outline-none'
            >
              <span className='rounded-circle border border-tertiary d-flex' style={{ padding: '10px 12px' }}>
                <FontAwesomeIcon icon={faChevronLeft} className='text-tertiary' size='sm' />
              </span>
              <span className='ml-3 text-tertiary' style={{ fontWeight: 600 }}>Previous</span>
            </button>}
        </div>
        <div className='col-2 order-lg-4 d-flex align-items-center justify-content-end justify-content-lg-start mt-4'>
          {nextButtonCondition &&
            <button
              type='button'
              onClick={() => { setCurrentQuestionIndex(currentQuestionIndex + 1) }}
              className='cursor-pointer py-1 px-2 d-flex align-items-center bg-white border-0 outline-none'
            >
              <span className='mr-3 text-tertiary' style={{ fontWeight: 600 }}>Next</span>
              <span className='rounded-circle border border-tertiary d-flex' style={{ padding: '10px 12px' }}>
                <FontAwesomeIcon icon={faChevronRight} className='text-tertiary' size='sm' />
              </span>
            </button>}
        </div>
        <div className='col-lg-8 col-12 order-lg-3 d-flex align-items-center justify-content-center'>
          <AssessmentQuestionContent
            answer={answer}
            setAnswer={setAnswer}
            currentQuestion={currentQuestion}
            handleAnswerQuestion={handleAnswerQuestion}
            lastQuestionCondition={lastQuestionCondition}
            handleCompleteAssessment={handleCompleteAssessment}
          />
        </div>
      </div>
    </Form>
  )
}

export default withRouter(AssessmentQuestion)
