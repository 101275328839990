import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Button } from '@mui/material'
import { CheckCircleOutline as Success, HighlightOff as Error } from '@mui/icons-material'

import { downloadExport, downloadPDF } from '../../redux/actions'
import { NotificationToast } from '../../components/tools'
import { findDefaultRoute, loaderArea, progressComponentTypes } from '../../utils'
import { StyledLoadingIndicator } from '../../utils/custom'

import { usePromiseTracker } from 'react-promise-tracker'

import queryString from 'query-string'

const FileDownload = ({ history, location }) => {
  const dispatch = useDispatch()
  const { isValidated = false, userType = '', userID = '' } = useSelector(state => state.auth)
  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.ASSESSMENT_DOWNLOAD })
  const parsedProps = queryString.parse(location?.search)

  const [downloadError, setDownloadError] = useState(false)

  const fireDownloadSuccess = useCallback(async ({ data = '', name = '' }) => {
    const a = document.createElement('a')

    if (a) {
      a.href = data
      a.download = name
      a.onclick = () => { return true }
      a.click()

      a.remove()
    }
  }, [])

  const fireDownloadFailure = () => {
    setDownloadError(true)
    NotificationToast(true, 'Unable to download file. Please try the link again or request a new file.')
  }

  useEffect(() => {
    if (parsedProps?.exportID) {
      setDownloadError(false)
      dispatch(downloadExport(parsedProps.exportID, fireDownloadSuccess, fireDownloadFailure))
    }

    if (parsedProps?.st) {
      setDownloadError(false)
      dispatch(downloadPDF(parsedProps.st, fireDownloadSuccess, fireDownloadFailure))
    }
  }, [parsedProps.exportID, parsedProps.st, dispatch, fireDownloadSuccess])

  return (
    <div className='text-center'>
      <h2 className='font-weight-bold'>
        {promiseInProgress ? 'Downloading' : !promiseInProgress && !downloadError ? 'File downloaded successfully!' : !promiseInProgress && downloadError ? 'Unable to download file. Please try the link again or request a new file.' : ''}
      </h2>

      {promiseInProgress
        ? (<StyledLoadingIndicator area={loaderArea.ASSESSMENT_DOWNLOAD} loaderType={progressComponentTypes.LINEAR_PROGRESS} />)
        : (
          <>
            <div style={{ marginBottom: '0.7em' }}>
              {downloadError ? <Error fontSize='large' color='error' /> : <Success fontSize='large' color='success' />}
            </div>
            <Button
              onClick={() => findDefaultRoute(isValidated, userType, userID, history)}
              color='success'
              size='lg'
              variant='contained'
            >
              {isValidated ? 'Return to Previous Page' : 'Return to Sign In'}
            </Button>
          </>)}
    </div>
  )
}

export default withRouter(FileDownload)
