import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import DefaultLayout from '../layouts/Default'
import AuthLayout from '../layouts/Auth'
import ReportLayout from '../layouts/Report'
import ReportApiLayout from '../layouts/ReportApi'

import Page404 from '../pages/auth/Page404'
import Page500 from '../pages/auth/Page500'
import FileDownload from '../pages/auth/FileDownload'
import ScrollToTop from '../components/ScrollToTop'

import { customerRoutes, authRoutes } from '.'
import AssessmentResults from '../pages/customer/AssessmentResults'
import AssessmentReport from '../pages/customer/AssessmentReport'
import Administration from '../pages/administration/Administration'
import AssessmentBuilder from '../pages/assessmentBuilder/Builder'

import { ThemeProvider } from '@mui/material/styles'
import theme from '../assets/styles/MuiTheme'
import { userTypes, findDefaultRoute, SessionResponse } from '../utils'
import { LoginToast } from '../components/tools'
import { removeErrors } from '../redux/actions'
import LogRocket from 'logrocket'

import queryString from 'query-string'
import MainHeader from '../components/MainHeader'

const Routes = (props) => {
  const { isValidated = false, userType = '', userID = '', error: authError = false, lastName = '', email: userEmail = '', builderAccess = false } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const logRef = useRef(true)

  const [tempIndustry, setTempIndustry] = useState(null)
  const [tempSector, setTempSector] = useState(null)
  const [tempAssessment, setTempAssessment] = useState(null)

  useEffect(() => {
    if (authError) {
      LoginToast()
      dispatch(removeErrors())
    }
  }, [authError, dispatch])

  // Use the log ref to determine the first load in and be sure we have identified the session user if authenticated
  // or set to anonymous if no logged in
  useMemo(() => {
    if (logRef.current) {
      logRef.current = false
      if (isValidated) {
        LogRocket.identify(userID, {
          name: lastName,
          email: userEmail
        })
      } else {
        LogRocket.identify()
      }
    }
  }, [isValidated, userEmail, lastName, userID])

  // reset the log ref on unmount
  useEffect(() => {
    return () => { logRef.current = true }
  }, [])

  const handleResetState = () => {
    setTempIndustry(null)
    setTempSector(null)
    setTempAssessment(null)
  }

  return (
    <ThemeProvider theme={theme}>
      {isValidated && <SessionResponse />}
      <Router>
        <ScrollToTop>
          <Switch>
            <Route
              path='/'
              exact
              render={(props) => {
                if (isValidated) {
                  return findDefaultRoute(isValidated, userType, userID)
                } else {
                  return <Redirect to={{ pathname: '/auth/sign-in' }} />
                }
              }}
            />
            {customerRoutes
              .map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact
                    render={(props) => {
                      if (isValidated && userType && userType === userTypes.COMPANY_USER) {
                        return (
                          <DefaultLayout width={route.width}>
                            <route.component
                              {...props}
                              width={route.width}
                              industry={tempIndustry}
                              sector={tempSector}
                              assessment={tempAssessment}
                              handleResetState={handleResetState}
                            />
                          </DefaultLayout>
                        )
                      } else {
                        return findDefaultRoute(isValidated, userType, userID)
                      }
                    }}
                  />
                )
              }
              )}

            <Route
              path='/administration'
              location={{ search: `?${userType}=${userID}` }}
              exact
              render={(props) => {
                if (isValidated && userType && userType !== userTypes.COMPANY_USER) {
                  return (
                    <DefaultLayout width={12} {...props}>
                      <Administration {...props} />
                    </DefaultLayout>
                  )
                } else {
                  return <Redirect to={{ pathname: '/error/404' }} />
                }
              }}
            />

            <Route
              path='/assessments/builder'
              exact
              render={(props) => {
                if (isValidated && userType && userType === userTypes.ADMIN_USER && Boolean(builderAccess)) {
                  return (
                    <>
                      <MainHeader />
                      <AssessmentBuilder {...props} />
                    </>
                  )
                } else {
                  return <Redirect to={{ pathname: '/error/404' }} />
                }
              }}
            />

            {authRoutes
              .map((route, index) =>
                <Route
                  key={index}
                  path={route.path}
                  exact
                  render={(props) => {
                    // Check for industry, sector, and assessment on login
                    const parsed = queryString.parse(props.location.search)

                    if (parsed?.industry) { setTempIndustry(parsed.industry) }
                    if (parsed?.sector) { setTempSector(parsed.sector) }
                    if (parsed?.assessment) { setTempAssessment(parsed.assessment) }

                    return (
                      <AuthLayout marginTop={route.marginTop}>
                        <route.component {...props} />
                      </AuthLayout>
                    )
                  }}
                />
              )}

            <Route
              path='/assessment-results/:id'
              exact
              render={props => {
                if (isValidated) {
                  return (
                    <ReportLayout>
                      <AssessmentResults {...props} />
                    </ReportLayout>
                  )
                } else {
                  return <Redirect to={{ pathname: '/auth/sign-in' }} />
                }
              }}
            />
            <Route
              path='/assessment-report/:id'
              exact
              render={props => {
                if (isValidated) {
                  return (
                    <ReportLayout>
                      <AssessmentReport {...props} />
                    </ReportLayout>
                  )
                } else {
                  return <Redirect to={{ pathname: '/auth/sign-in' }} />
                }
              }}
            />
            <Route
              path='/assessment-report/:id/:userID/:token'
              exact
              render={props => {
                return (
                  <ReportApiLayout>
                    <AssessmentReport {...props} />
                  </ReportApiLayout>
                )
              }}
            />
            <Route
              path='/assessment-report/:userAssessmentID/:token'
              exact
              render={props => {
                return (
                  <ReportApiLayout>
                    <AssessmentReport {...props} />
                  </ReportApiLayout>
                )
              }}
            />
            <Route
              path='/download'
              exact
              render={props => {
                return (
                  <AuthLayout>
                    <FileDownload {...props} />
                  </AuthLayout>
                )
              }}
            />
            <Route
              path='/error/500'
              exact
              render={() => (
                <AuthLayout>
                  <Page500 />
                </AuthLayout>
              )}
            />
            <Route
              path='/error/404'
              exact
              render={() => (
                <AuthLayout>
                  <Page404 />
                </AuthLayout>
              )}
            />

            <Route
              path='*'
              exact
              render={() => {
                if (isValidated) {
                  return (
                    <AuthLayout>
                      <Page404 />
                    </AuthLayout>
                  )
                } else {
                  return <Redirect to={{ pathname: '/auth/sign-in' }} />
                }
              }}
            />

          </Switch>
        </ScrollToTop>
      </Router>
    </ThemeProvider>
  )
}

export default Routes
