import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import moment from 'moment'
import { faCheckCircle, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ListGroupItem } from 'reactstrap'
import ConfirmationModal from '../../components/ConfirmationModal'
import InformationModal from '../../components/InformationModal'
import { NotificationToast } from '../../components/tools'
import { getUserAssessmentResults, updateAssessmentStatus, getUserAssessments, getAssessmentFlowQuestions } from '../../redux/actions'
import { utils } from '../../utils/utils'
import { assessmentStatus, assessmentStatusLabel } from '../../utils'

const AssessmentRow = (props) => {
  const { assessment, fireQuestionsSuccess } = props
  const { userID } = useSelector(state => state.auth)
  const history = useHistory()

  const { status = '', userAssessmentID = '', createdAt, assessmentID } = assessment
  const dispatch = useDispatch()

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: '',
    body: '',
    onClose: null
  })

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: '',
    body: ''
  }

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  )

  const fireUpdateSuccess = () => {
    dispatch(getUserAssessments(userID))
  }

  const fireUpdateFailure = () => {
    NotificationToast(true, 'Unable to Update Assessment Status')
  }

  const onStatusChange = async (userAzID, currentStatus) => {
    const newStatus = currentStatus && currentStatus === assessmentStatus.COMPLETED ? assessmentStatus.IN_PROGRESS : assessmentStatus.COMPLETED
    const updateInfo = { userAssessmentID: userAzID, status: newStatus, userID }
    setConfirmationModal({
      isOpen: true,
      confirmColor: 'warning',
      onSubmit: async () => {
        try {
          dispatch(updateAssessmentStatus(updateInfo, fireUpdateSuccess, fireUpdateFailure))
        } catch (err) { }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal)
      },
      title: 'Complete Assessment',
      body: `<span class='text-center'>Are you sure you want to mark this assessment as ${status && status === assessmentStatus.COMPLETED ? 'not' : ''} completed?</span>`
    })
  }

  const fireResultsSuccess = () => {
    history.push(`/assessment-results/${userAssessmentID}`)
  }

  const handleViewResults = (user, assessment) => {
    if (user && assessment) {
      dispatch(getUserAssessmentResults({ userID: user, userAssessmentID: assessment }, fireResultsSuccess))
    }
  }

  const handleStartAssessment = () => {
    dispatch(getAssessmentFlowQuestions(assessmentID, userAssessmentID, fireQuestionsSuccess, fireFailure))
  }

  const fireFailure = (message = 'Something went wrong.') => {
    NotificationToast(true, message, false)
  }

  return (
    <>
      <ListGroupItem
        className={`row bg-light border rounded py-2 my-1 d-flex justify-content-between align-items-center border-${status === assessmentStatus.COMPLETED ? 'primary' : 'muted'} text-primary`}
        tag='div'
      >
        <div className='d-flex col-xs-12 align-items-center mb-1'>
          <Button color={status === assessmentStatus.COMPLETED ? 'success' : 'warning'} className='cursor-default'>
            {assessmentStatusLabel[status]}
          </Button>
          <div className={`ml-4 ${status !== assessmentStatus.COMPLETED ? 'text-muted' : ''}`}>
            <FontAwesomeIcon icon={status && status !== assessmentStatus.IN_PROGRESS ? faCheckCircle : faClock} />
            <span className='ml-2'>{moment(createdAt).format('MMMM DD, YYYY')}</span>
          </div>
        </div>
        <div>
          {status && status === assessmentStatus.COMPLETED
            ? <span className='text-underline cursor-pointer' onClick={() => handleViewResults(userID, userAssessmentID)}>View Results</span>
            : <span className='text-underline cursor-pointer' onClick={() => handleStartAssessment()}>Continue</span>}
          <span hidden={utils.isProduction()} className='text-underline cursor-pointer mx-3' onClick={() => onStatusChange(userAssessmentID, status)}>{`Mark as as ${status && status === assessmentStatus.COMPLETED ? 'not' : ''} Completed`}</span>
        </div>
      </ListGroupItem>

      {confirmationModal.isOpen &&
        <ConfirmationModal {...confirmationModal} />}

      {informationModal.isOpen &&
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => setInformationModal({ isOpen: false, title: '', body: '', onClose: null })}
        />}
    </>
  )
}

export default AssessmentRow
