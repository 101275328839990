import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { usePromiseTracker } from 'react-promise-tracker'
import { Button } from 'reactstrap'
import { Typography } from '@mui/material'
import { faFileDownload, faShareSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ResultFeedback from './ResultFeedback'
import Loader from '../Loader'
import { assessmentReportHeaderClasses } from '../../utils/bootstrapClassNames'
import { getAssessmentReportPDF } from '../../redux/actions'
import { loaderArea } from '../../utils/variables'
import { NotificationToast } from '../tools'

import { utils } from '../../utils/utils'
import { DateTime } from 'luxon'

const AssessmentReportHeader = (props) => {
  const { pageAssessment, setShowShareModal = () => { }, disableHeaderButtons = false } = props
  const dispatch = useDispatch()
  const { promiseInProgress: shareResultsLoading } = usePromiseTracker({ area: loaderArea.SHARE_ASSESSMENT_REPORT })
  const { userAssessmentResults } = useSelector(state => state.userAssessment)
  const { company = '' } = useSelector(state => state.auth)

  const { userAssessmentID, createdAt = '', firstName = '', lastName = '', sectorName = '', userCompany = '' } = userAssessmentResults
  const formatCreatedAt = DateTime.fromISO(createdAt).toLocaleString(DateTime.DATE_SHORT)

  const [pdfLoading, setPdfLoading] = useState(false)

  const fireDownloadFailure = () => { NotificationToast(true, 'Unable to download report. Please try again.') }

  const onDownloadPdf = async () => {
    if (pdfLoading) { return }
    setPdfLoading(true)

    try {
      const pdfResult = await dispatch(getAssessmentReportPDF(userAssessmentID, fireDownloadFailure))

      const sanitizedCompany = company.replace(/[,.\s]/g, '_')

      if (pdfResult) {
        const link = document.createElement('a')
        link.href = pdfResult
        link.download = `AssessmentReport-${sanitizedCompany}`
        document.body.append(link)
        link.click()
        link.remove()
      }
    } catch (err) {
      console.log(err)
    } finally {
      setPdfLoading(false)
    }
  }

  return (
    <div className={assessmentReportHeaderClasses.mainContainer}>
      <h1 className={`text-${utils.getScoreBorderColor(pageAssessment?.assessmentScore)} text-xl`}>{`${pageAssessment?.assessmentScore || 0}%`}</h1>
      <h3>Your Score</h3>
      <ResultFeedback assessmentScore={pageAssessment?.assessmentScore} />

      {!disableHeaderButtons &&
        <div className={assessmentReportHeaderClasses.headerButtonsContainer}>
          <Button size='lg' color='primary' className={assessmentReportHeaderClasses.pdfButton} onClick={onDownloadPdf}>
            <FontAwesomeIcon icon={faFileDownload} size='lg' />
            {pdfLoading
              ? (
                <div className='min-width-50'>
                  <Loader size='sm' color='white' className='mr-2' />
                </div>)
              : (
                <small className='ml-2'>Download PDF</small>)}
          </Button>
          <Button size='lg' color='white' className={assessmentReportHeaderClasses.shareResultsButton} onClick={() => setShowShareModal(true)}>
            <FontAwesomeIcon icon={faShareSquare} size='lg' />
            {
              shareResultsLoading
                ? (
                  <div className='min-width-50'>
                    <Loader size='sm' color='white' className='mr-2' />
                  </div>)
                : (
                  <small className='ml-2'>Share Results</small>)
            }
          </Button>
        </div>}
      {/* At flex sm and up it will be a row, otherwise it's a column */}
      <div className={assessmentReportHeaderClasses.headerInfoContainer}>
        <div className={assessmentReportHeaderClasses.headerInfoItem}>
          <Typography variant='caption'>Name</Typography>
          <Typography variant='body1'>{firstName} {lastName}</Typography>
        </div>
        <div className={assessmentReportHeaderClasses.headerInfoItem}>
          <Typography variant='caption'>Company</Typography>
          <Typography variant='body1'>{userCompany}</Typography>
        </div>
        <div className={assessmentReportHeaderClasses.headerInfoItem}>
          <Typography variant='caption'>Sector</Typography>
          <Typography variant='body1'>{sectorName}</Typography>
        </div>
        <div className={assessmentReportHeaderClasses.headerInfoItem}>
          <Typography variant='caption'>Date</Typography>
          <Typography variant='body1'>{formatCreatedAt}</Typography>
        </div>
      </div>
    </div>
  )
}

export default withRouter(AssessmentReportHeader)
