/* eslint camelcase: off */

import { GET_USER_ASSESSMENTS, GET_USER_ASSESSMENT_RESULTS } from '../types'
import { trackPromise } from 'react-promise-tracker'
import { parseResponse, responseTypes } from '../../lib'
import { assessmentStatus, loaderArea } from '../../utils'

import log from 'loglevel'

const apiPath = process.env.REACT_APP_API_URL

// Return a list of user assessments
export const getUserAssessments = (userID, fireSuccess = () => {}, fireFailure = () => {}) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/user/${userID}/assessment`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        dispatch({
          type: GET_USER_ASSESSMENTS,
          payload: data
        })

        // determine if we have any assessments returned and then find if any of them are in progress
        const assessmentsLength = data?.length
        const assessmentInProgress = data?.find(x => x.status === assessmentStatus.IN_PROGRESS)
        let assessmentIDInProgress = null
        let userAssessmentIDInProgress = null
        if (assessmentInProgress) {
          const { assessmentID, userAssessmentID } = assessmentInProgress
          assessmentIDInProgress = assessmentID
          userAssessmentIDInProgress = userAssessmentID
        }

        // return the boolean and the assID and userAssID (if available)
        fireSuccess(assessmentsLength, assessmentIDInProgress, userAssessmentIDInProgress)

        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to get user assessments.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Creates a new user assessment
export const createUserAssessment = (info, fireSuccess = () => {}, fireFailure = () => {}) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const { userID, industryID, sectorID, assessmentID } = info

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ industryID, sectorID, assessmentID })
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/user/${userID}/assessment`, options), 'post-user-assessment')

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()
        const { userAssessmentID, assessmentID } = data
        fireSuccess({ userAssessmentID, assessmentID })

        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to create user assessment.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Fetches the results of a user assessment
export const getUserAssessmentResults = ({ userID = '', userAssessmentID = '', authToken = '' }, fireSuccess = () => {}, fireFailure = () => {}) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      // authToken is used on this endpoint when getting data for pdf on AssessmentReport.js
      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${!authToken ? token : authToken}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/user/${userID}/assessment/${userAssessmentID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        dispatch({
          type: GET_USER_ASSESSMENT_RESULTS,
          payload: data
        })

        fireSuccess(userAssessmentID)

        return data
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to get user assessments.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

export const getShareAssesmentResults = (authToken = '', fireSuccess = () => {}, fireFailure = () => {}) => {
  return async (dispatch, getState) => {
    try {
      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/pdf/getassessmentreport`, options))
      const parsedResponse = parseResponse(response, dispatch)

      if (parsedResponse && parsedResponse.type === responseTypes.SUCCESS) {
        const { data } = await response.json()

        dispatch({
          type: GET_USER_ASSESSMENT_RESULTS,
          payload: data
        })

        fireSuccess()

        return data
      }

      fireFailure()
      return false
    } catch (error) {
      log.error(error)
      fireFailure()
      return false
    }
  }
}

// Modify a user's assessment status
export const updateAssessmentStatus = ({ userAssessmentID, status, userID }, fireSuccess = () => {}, fireFailure = () => {}) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ status })
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/user/${userID}/assessment/${userAssessmentID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess(userAssessmentID)

        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to update user assessment status.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Deletes a user assessment ADMIN ONLY
export const deleteUserAssessment = ({ userAssessmentID, userID }, fireSuccess = () => {}, fireFailure = () => {}) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/user/${userID}/assessment/${userAssessmentID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to delete user assessment.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

// Creates or updates a user's answer to an assessment question
export const changeUserAssessmentAnswer = (info, fireSuccess = () => {}, fireFailure = () => {}) => {
  const { userID, userAssessmentID, questionID, questionOptionID } = info
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ questionID, questionOptionID })
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/user/${userID}/assessment/${userAssessmentID}/answers`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess(questionOptionID)

        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to create user assessment.')

        return false
      }
    } catch (error) {
      console.log('catch')
      log.error(error)
    }
  }
}

// Removes a user's answer to an assessment question ADMIN ONLY
export const deleteUserAssessmentAnswer = (info, fireSuccess = () => {}, fireFailure = () => {}) => {
  const { userID, userAssessmentID, userAssessmentResponseID } = info
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/user/${userID}/assessment/${userAssessmentID}/answers/${userAssessmentResponseID}`, options))

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse) { return false }

      if (parsedResponse.type === responseTypes.SUCCESS) {
        fireSuccess()

        return true
      } else {
        const [data] = await response.json()
        fireFailure(data)

        log.info('Failed to create user assessment.')

        return false
      }
    } catch (error) {
      log.error(error)
    }
  }
}

export const contactExpertAboutAssessment = (data, fireSubAction = () => {}) => {
  return async (dispatch, getState) => {
    try {
      const { token = '', userID = '' } = getState().auth

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        ...(data && { body: JSON.stringify({ ...data }) })
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/user/${userID}/contact-expert`, options))

      const parsedResponse = parseResponse(response, dispatch, true)
      let actionError = false

      if (!parsedResponse || parsedResponse?.error) { actionError = true }

      fireSubAction(actionError)
      if (actionError) { log.info('Failed to create user assessment.') }
    } catch (error) {
      log.error(error)
      fireSubAction(true)
    }
  }
}

export const fetchAssessmentSurveyQuestions = (info, fireSuccess = () => {}, fireFailure = () => {}) => {
  return async (dispatch, getState) => {
    try {
      const { userID, userAssessmentID } = info
      const { token = '' } = getState().auth

      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      const response = await trackPromise(window.fetch(`${apiPath}/api/user/${userID}/assessment/${userAssessmentID}/survey`, options), loaderArea.GET_ASSESSMENT_SURVEY)

      const parsedResponse = parseResponse(response, dispatch)

      if (!parsedResponse || parsedResponse?.type !== responseTypes.SUCCESS) { fireFailure(); return false }

      const { data = [] } = await response.json()
      fireSuccess(data)
      return true
    } catch (err) {
      log.error(err)
      fireFailure()
    }
  }
}

export const postAssessmentSurveyAnswers = (userID, userAssessmentID, info) => {
  return async (dispatch, getState) => {
    try {
      const { token = '' } = getState().auth

      const body = JSON.stringify({ ...info })

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body
      }

      await window.fetch(`${apiPath}/api/user/${userID}/assessment/${userAssessmentID}/survey`, options)
      return true
    } catch (err) {
      log.error(err)
      return true
    }
  }
}
